import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Kellen Axten</h1>
        <p>Principal Product Manager Technical | Sustainability & Generative AI</p>
        <nav>
          <ul>
            <li><a href="#about">About</a></li>
            <li><a href="#experience">Experience</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
      </header>
      <section id="about">
        <h2>About Me</h2>
        <p>
          I am a data-driven principal product manager with 14+ years of experience specializing in sustainability, machine learning, and accessibility. I am passionate about building technology for good and am uniquely skilled at building effective solutions for complex customer problems at scale.
        </p>
      </section>
      <section id="experience">
        <h2>Experience</h2>
        <div>
          <h3>Amazon</h3>
          <p>Principal Product Manager Technical</p>
          <p>
            Current role: Lead machine learning product development for Worldwide Sustainability focused on product lifecycle assessment using large language models.
          </p>
          <p>
            Past roles: Program and product management across brand and trademark protection, customer service, accessibility, and secure cloud computing products.
          </p>
        </div>
        <div>
          <h3>US Army Reserve</h3>
          <p>Staff College Instructor</p>
          <p>Innovation Officer</p>
          <p>Forward Support Engineer</p>
        </div>
        <div>
          <h3>US Army National Guard</h3>
          <p>Company Commander</p>
          <p>Information Security Team Lead</p>
        </div>
        <div>
          <h3>Terex</h3>
          <p>Senior Manufacturing Engineer</p>
        </div>
        <div>
          <h3>US Army</h3>
          <p>Engineer Officer</p>
        </div>
      </section>
      <section id="portfolio">
        <h2>Portfolio</h2>
        <p>
          Check out my work on <a href="https://github.com/kelaxten" target="_blank" rel="noopener noreferrer">GitHub</a>.
        </p>
        <div>
          <h3>Publications</h3>
          <ul>
           <a href="https://www.climatechange.ai/papers/neurips2023/5" target="_blank" rel="noopener noreferrer">Flamingo: Environmental Impact Factor Matching for Life Cycle Assessment with Zero-Shot ML [NeurIPS 2023 Climate Change AI Workshop]</a>
          </ul>
          <ul>
           <a href="https://aws.amazon.com/solutions/guidance/environmental-impact-factor-mapping-on-aws/" target="_blank" rel="noopener noreferrer">Guidance for Environmental Impact Factor Mapping on AWS</a>
          </ul>
        </div>
      </section>
      <section id="contact">
        <h2>Contact</h2>
        <p>
          Connect with me on <a href="https://www.linkedin.com/in/kellenaxten" target="_blank" rel="noopener noreferrer">LinkedIn</a>.
        </p>
      </section>
    </div>
  );
}

export default App;
